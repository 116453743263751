export default {
  "guests": "qHg",
  "guests--upsell-screen": "qHD",
  "no-members": "qHV",
  "teams-header": "qHL",
  "empty": "qHT",
  "invite-text": "qHz",
  "body": "qHa",
  "members": "qHH",
  "members-list": "qHO",
  "members-title": "qHm caption-bold",
  "member": "qHp",
  "details": "qHx",
  "kyc-disclaimer": "qHw",
  "kyc-disclaimer-width": "qHN"
};

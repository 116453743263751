export default {
  "member-details": "qaK",
  "header": "qaG",
  "header-img": "qar",
  "invited-persona": "qab",
  "revoked-persona": "qay",
  "invited-icon": "qaU",
  "revoked-icon": "qaj",
  "dropdown": "qaW",
  "header-body": "qau",
  "name": "qHS title-3",
  "email": "qHc caption-bold",
  "actions": "qHq body-2",
  "actions-cards": "qHZ",
  "actions-transactions": "qHR",
  "body": "qHQ",
  "expense-permissions-section": "qHe",
  "body-title": "qHB title-4",
  "body-attr": "qHE",
  "body-label": "qHd",
  "attr-buttons": "qHI",
  "body-role": "qHt small",
  "details-actions": "qHM",
  "actions-item": "qHP",
  "disclaimer": "qHl",
  "permissions": "qHX",
  "permission": "qHC",
  "permission-label": "qHk",
  "permission-check": "qHJ",
  "permission-missing": "qHs",
  "editable-section": "qHF"
};

export default {
  "header": "qnn",
  "content": "qni",
  "read-only": "qnA",
  "disclaimer": "qnY",
  "title": "qnh",
  "card-container": "qnf",
  "representatives-section": "qnK",
  "disabled-href": "qnG"
};

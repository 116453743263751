export default {
  "container": "qnu",
  "wrapper": "qiS",
  "grey-header-block": "qic",
  "connect-app-header": "qiq",
  "connect-app-header-link": "qiZ",
  "connect-app-header-content": "qiR",
  "connect-app-header-content-default": "qiQ",
  "connect-app-header-content-left": "qie",
  "button-block": "qiB",
  "connect-app-body": "qiE",
  "connect-app-body-left-content": "qid",
  "connect-app-body-sidebar": "qiI"
};

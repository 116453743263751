export default {
  "body": "qpp",
  "members": "qpx",
  "members-results": "qpw",
  "members-list": "qpN",
  "members-title": "qpv caption-bold",
  "member": "qpo",
  "empty-illustration": "qpn",
  "empty-title": "qpi title-3",
  "empty-description": "qpA body-2",
  "invitable-members": "qpY",
  "details": "qph",
  "pagination-footer": "qpf"
};

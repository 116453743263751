export default {
  "bank-accounts": "qnR l-app-content__wrapper",
  "header-wrapper": "qnQ",
  "scrolled-top": "qne",
  "header": "qnB l-app-content__page-header",
  "create-account-button": "qnE btn btn--primary",
  "main": "qnd",
  "accounts": "qnI",
  "subtitle-container": "qnt",
  "subtitle": "qnM title-3 mb-16",
  "amount": "qnP body-2",
  "list--cashbeeSavings": "qnl"
};

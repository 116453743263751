export default {
  "container": "qNs",
  "form-container": "qNF",
  "title": "qNg",
  "title-border": "qND",
  "tabs-container": "qNV",
  "preview-container": "qNL",
  "email-preview-container": "qNT",
  "close-button": "qNz btn btn--icon-only btn--tertiary btn--large"
};

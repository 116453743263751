export default {
  "page-wrapper": "qTX",
  "container": "qTC",
  "spinner-container": "qTk",
  "left-panel": "qTJ",
  "form": "qTs",
  "left-content": "qTF",
  "form-footer": "qTg",
  "delete-button": "qTD",
  "header": "qTV",
  "preview-container": "qTL",
  "email-preview": "qTT",
  "close-button": "qTz btn btn--icon-only btn--tertiary btn--large"
};

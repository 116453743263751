export default {
  "page-wrapper": "qgu",
  "container": "qDS",
  "sidebar": "qDc",
  "search-input": "qDq",
  "organizations-list": "qDZ",
  "content": "qDR",
  "no-result": "qDQ",
  "lottie-illustration": "qDe",
  "accounting-header": "qDB",
  "logo": "qDE",
  "accounting-title": "qDd"
};

import { action } from '@ember/object';
import { service } from '@ember/service';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import {
  DIRECT_DEBIT_COLLECTION_ACTIVATION_STATUS,
  SDD_MANDATE_STATUS,
} from 'qonto/constants/direct-debit-collections';
import { INVOICE_STATUS_DROPDOWN } from 'qonto/constants/invoice-subscriptions';
import InvoiceSubscriptionsNewController from 'qonto/routes/invoice-subscriptions/new/controller';
import isFunction from 'qonto/utils/is-function';

export default class InvoiceSubscriptionsEditController extends InvoiceSubscriptionsNewController {
  disclaimer = Disclaimer.Inline;

  @service router;

  get editSubscriptionOrigin() {
    let { currentRoute } = this.router;
    return { origin: 'invoice-subscriptions.edit', params: currentRoute?.params?.id };
  }

  get willCreateDraftInvoice() {
    if (!variation('feature--boolean-ar-document-templates-slice-1')) {
      return false;
    } else {
      return this.model.subscription?.invoiceStatus === INVOICE_STATUS_DROPDOWN.DRAFT;
    }
  }

  @action toggleDirectDebitAndEmailVisibility(status) {
    this.willCreateDraftInvoice = status === INVOICE_STATUS_DROPDOWN.DRAFT;
  }

  @action showClosePopup() {
    return this.modals.open('popup/confirmation', {
      title: this.intl.t('recurring-invoices.edit.form.confirmation-popup.title'),
      description: this.intl.t('recurring-invoices.edit.form.confirmation-popup.subtitle'),
      cancel: this.intl.t('recurring-invoices.edit.form.confirmation-popup.cancel-cta'),
      confirm: this.intl.t('recurring-invoices.edit.form.confirmation-popup.save-cta'),
      cancelTask: this.closeTask,
      confirmTask: this.onConfirmSaveSubscriptionTask,
    });
  }

  closeTask = dropTask(async closeModal => {
    this.segment.track('recurring-invoices_edit_canceled');
    this.router.transitionTo('invoice-subscriptions.index');
    await closeModal();
  });

  saveTask = dropTask(async closeModal => {
    // When we're creating a draft recurring invoice, direct debit is not allowed
    if (this.willCreateDraftInvoice) {
      this.model.subscription.directDebitEnabled = false;
    }

    try {
      await this.model.subscription.save();
      // Remove locally stored items because backend return copies of those items with new ids
      this.model.subscription.clearItemsWithNoId();
      this.toastFlashMessages.toastSuccess(this.intl.t('recurring-invoices.toasts.success.edit'));
      this.segment.track('recurring-invoices_edit_confirmed', {
        success: true,
      });

      if (
        this.model.subscription.directDebitEnabled &&
        this.model.sddActivation.status === DIRECT_DEBIT_COLLECTION_ACTIVATION_STATUS.ACTIVATED &&
        this.model.directDebitMandate?.status !== SDD_MANDATE_STATUS.APPROVED
      ) {
        return this.router.transitionTo(
          'invoice-subscriptions.sdd.setup',
          this.model.subscription.id
        );
      }
      this.router.transitionTo('invoice-subscriptions.index');
    } catch (error) {
      this.segment.track('recurring-invoices_edit_confirmed', {
        success: false,
      });
      this._handleSaveSubscriptionError(error);
    } finally {
      if (isFunction(closeModal)) {
        await closeModal();
      }
    }
  });

  _trackSuccessSave(directDebitEnabled, sddActivation) {
    this.segment.track('recurring-invoices_edit_confirmed', {
      autoDebit: directDebitEnabled,
      ...(directDebitEnabled &&
        sddActivation.hasGuarding && {
          guarding_percentage: sddActivation.guardingDepositPercentage,
        }),
    });
  }

  _getSuccessMessage(directDebitEnabled, isNewMandate) {
    return this.intl.t(
      directDebitEnabled
        ? isNewMandate
          ? 'recurring-invoices.toasts.success.edit-with-sdd-new-mandate'
          : 'recurring-invoices.toasts.success.edit-with-sdd'
        : 'recurring-invoices.toasts.success.edit'
    );
  }
}

export default {
  "container": "qYX",
  "security-method": "qYC",
  "icon": "qYk",
  "body": "qYJ",
  "status": "qYs body-2",
  "device": "qYF",
  "device-icon": "qYg",
  "subtitle": "qYD body-2",
  "current": "qYV"
};

export default {
  "team": "qpK",
  "no-members": "qpG",
  "teams-header": "qpr",
  "header-search": "qpb",
  "search-bar": "qpy",
  "invite": "qpU body-2",
  "invite-text": "qpj",
  "empty-illustration": "qpW",
  "empty-title": "qpu title-3",
  "empty-description": "qxS body-2",
  "pagination-footer": "qxc",
  "filter-search": "qxq",
  "fullsize": "qxZ",
  "tabs-container": "qxR",
  "tabs-separator": "qxQ",
  "tooltip-wrapper": "qxe",
  "invite-cta": "qxB",
  "kyc-disclaimer": "qxE",
  "kyc-disclaimer-width": "qxd"
};

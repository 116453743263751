export default {
  "dropdown-icon": "qfN",
  "dropdown": "qfv",
  "download-button": "qfo",
  "connections-banner": "qfn",
  "recommendation-cards": "qfi",
  "wrapper-with-cards": "qfA",
  "transactions-wrapper": "qfY",
  "separator": "qfh",
  "with-transaction": "qff",
  "annual-billing-banner": "qfK",
  "mt-132": "qfG",
  "transactions-sidebar": "qfr",
  "empty-state-wrapper": "qfb",
  "wrapper-with-modular": "qfy",
  "modular-table-container": "qfU",
  "modular-table-sidebar": "qfj",
  "modular-table-no-overlay": "qfW",
  "sidebar-overlay": "qfu",
  "overlay-visible": "qKS"
};

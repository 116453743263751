import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class SupplierSubscriptionsIndexRoute extends Route {
  // This file will house code to check for first-time usage of the supplier subscriptions feature
  // and redirect to the appropriate page based on that.

  @service router;

  afterModel() {
    return this.router.replaceWith('supplier-subscriptions.list');
  }
}

export default {
  "container": "qYG",
  "header-cell": "qYr caption-bold",
  "col-8": "qYb",
  "table": "qYy",
  "cell": "qYU body-2",
  "cell-content": "qYj",
  "row": "qYW",
  "empty": "qYu",
  "quick-actions": "qhS",
  "card-infos": "qhc",
  "card-digits": "qhq",
  "empty-state": "qhZ"
};

export default {
  "mt-16": "qnD",
  "title": "qnV",
  "avatar": "qnL",
  "edit-organization-form": "qnT",
  "dropdown-content": "qnz",
  "city-block": "qna",
  "zip-code-input": "qnH",
  "cancel-button": "qnO",
  "error-message": "qnm",
  "nature-of-operations": "qnp",
  "error": "qnx",
  "tax-country": "qnw",
  "tooltip": "qnN",
  "legal-code": "qnv",
  "activity-type": "qno"
};

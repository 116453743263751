export default {
  "container": "qxJ",
  "content": "qxs",
  "logos": "qxF",
  "logo": "qxg",
  "animation": "qxD",
  "title": "qxV title-2 mb-32",
  "avatar": "qxL",
  "actions": "qxT mb-32",
  "footer": "qxz body-2"
};

export default {
  "container": "qiX",
  "link-grey": "qiC",
  "slack-section-title": "qik title-4 mb-8",
  "edit-channel-text": "qiJ body-2 mb-16",
  "rule-section-title": "qis title-4 mb-16",
  "rule-cards-container": "qiF",
  "edit-slack-container": "qig",
  "rule-card": "qiD",
  "add-rule-container": "qiV",
  "add-rule-link-button": "qiL",
  "add-rule-text": "qiT",
  "empty-state": "qiz",
  "empty-state-textblock": "qia",
  "empty-state-title": "qiH title-2",
  "empty-state-description": "qiO body-2",
  "empty-state-illustration": "qim",
  "rules": "qip"
};

import { setOwner } from '@ember/owner';
// eslint-disable-next-line no-duplicate-imports -- needed because Owner is the default export
import type Owner from '@ember/owner';
import { service, type Registry as Services } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import type { BasketItem } from '@repo/domain-kit/payment-links';
import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import type PaymentLinkModel from 'qonto/models/payment-link';
import type PaymentLinkMethodModel from 'qonto/models/payment-link-method';
import { PopupDestructive } from 'qonto/react/components/popups/destructive';
import type { Amount } from 'qonto/react/models/amount';

class PaymentLinksCreationFlowSetupDataContext {
  @tracked expandedItems: BasketItem[] = [];
  @tracked selectedItems: BasketItem[] = [];
  @tracked selectedMethods: PaymentLinkMethodModel['method'][] = [];
  @tracked totalAmount: Amount<string> = {
    value: '0.00',
    currency: 'EUR',
  };
  @tracked organizationName: string = '';
  @tracked vatRatesCategories: number[] = [];
  @tracked settings: PaymentLinkModel['settings'] = {
    reusable: true,
    redirectUrl: '',
  };
  @tracked checkoutUrl?: string;
}

export default class PaymentLinksCreationFlowSetup {
  @service declare store: Services['store'];
  @service declare router: Services['router'];
  @service declare abilities: Services['abilities'];
  @service declare homePage: Services['homePage'];
  @service declare mollie: Services['mollie'];
  @service declare flow: Services['flow'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare reactPopup: Services['reactPopup'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  initialStepId = 'products-and-services';

  dataContext: PaymentLinksCreationFlowSetupDataContext;

  constructor(owner: Owner) {
    setOwner(this, owner);

    this.dataContext = new PaymentLinksCreationFlowSetupDataContext();
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'cards');

    await this.mollie.fetchConnectionTask.perform();

    let isEnabled = this.mollie.isEnabled;
    let canWritePaymentLink = this.abilities.can('write paymentLink');
    let canAccessStandalone = variation('feature--boolean-standalone-payment-links');

    if (!canAccessStandalone || !isEnabled || !canWritePaymentLink) {
      this.homePage.replaceWithDefaultPage();
    }

    this.dataContext.organizationName = this.organizationManager.organization.name;
    this.dataContext.vatRatesCategories = this.organizationManager.organization.vatRatesCategories;
  }

  onComplete() {
    this.router.transitionTo('payment-links.index');
  }

  openAbortModalTask = dropTask(
    async () =>
      await this.reactPopup.open(PopupDestructive, {
        title: this.intl.t('payment-link.create.step-1.leave-modal.title'),
        subtitle: this.intl.t('payment-link.create.step-1.leave-modal.subtitle'),
        cancelText: this.intl.t('payment-link.create.step-1.leave-modal.cancel'),
        confirmText: this.intl.t('payment-link.create.step-1.leave-modal.submit'),
      })
  );

  onAbortTask = dropTask(async (dataContext, { id: currentStepId }) => {
    const hasUnsavedChanges = dataContext.selectedItems.length > 0;
    if (hasUnsavedChanges && currentStepId !== 'success') {
      const result = await this.openAbortModalTask.perform();
      if (result === 'confirm') {
        this.segment.track('payment-links_creation_canceled');
        this.router.transitionTo('payment-links.index');
        return true;
      }
      return false;
    }

    this.router.transitionTo('payment-links.index');
    return false;
  });
}

export default {
  "title": "qYZ title-3",
  "personal-profile-container": "qYR",
  "personal-profile-section": "qYQ",
  "member-info-title": "qYe",
  "kyc-complete-profile": "qYB",
  "info-fields": "qYE",
  "field": "qYd",
  "address-fields": "qYI",
  "field-span": "qYt",
  "input": "qYM",
  "cancel": "qYP"
};

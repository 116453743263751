export default {
  "page": "qNr",
  "container": "qNb",
  "form-container": "qNy",
  "preview-container": "qNU",
  "pdf-preview-container": "qNj",
  "content": "qNW",
  "title": "qNu",
  "tabs-container": "qvS",
  "close-button": "qvc btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qvq",
  "header": "qvZ",
  "without-tabs": "qvR"
};

export default {
  "wrapper": "qAF",
  "integrations": "qAg",
  "description": "qAD body-2",
  "api": "qAV flex flex-column",
  "api-container": "qAL flex large",
  "api-input": "qAT large",
  "api-btn": "qAz",
  "key-input": "qAa"
};

export default {
  "supplier-invoices-page": "qhG",
  "supplier-invoices-page-header": "qhr",
  "filters-container": "qhb",
  "search-bar": "qhy",
  "filters": "qhU",
  "bulk-actions": "qhj",
  "item-count": "qhW",
  "higher-index": "qhu",
  "title-wrapper": "qfS",
  "title": "qfc",
  "subtitle": "qfq",
  "table-container": "qfZ",
  "file-dropzone": "qfR",
  "dropzone-visible": "qfQ",
  "header-actions": "qfe",
  "header-wrapper": "qfB",
  "disclaimer-wrapper": "qfE"
};

export default {
  "page": "qvK",
  "container": "qvG",
  "form-container": "qvr",
  "preview-container": "qvb",
  "pdf-preview-container": "qvy",
  "content": "qvU",
  "title": "qvj",
  "tabs-container": "qvW",
  "close-button": "qvu btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qoS",
  "header": "qoc",
  "without-tabs": "qoq"
};

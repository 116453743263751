export default {
  "container": "qae",
  "page-wrapper": "qaB",
  "header": "qaE",
  "back-button-wrapper": "qad",
  "header-inner": "qaI",
  "header-main-wrapper": "qat",
  "header-main": "qaM",
  "header-right": "qaP",
  "content-wrapper": "qal",
  "content": "qaX",
  "content-body": "qaC",
  "sidebar": "qak"
};

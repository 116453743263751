export default {
  "page-container": "qmV",
  "form": "qmL",
  "close-button": "qmT btn btn--icon-only btn--tertiary btn--large",
  "preview": "qmz",
  "preview-padding": "qma",
  "title": "qmH",
  "header": "qmO",
  "without-tabs": "qmm",
  "form-section": "qmp",
  "form-footer": "qmx",
  "disclaimer-text": "qmw"
};

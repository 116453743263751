export default {
  "page": "qwh",
  "container": "qwf",
  "form-container": "qwK",
  "preview-container": "qwG",
  "pdf-preview-container": "qwr",
  "content": "qwb",
  "header": "qwy",
  "close-button": "qwU btn btn--icon-only btn--tertiary btn--large"
};

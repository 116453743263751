export default {
  "signin": "qhF",
  "form": "qhg",
  "illustration": "qhD",
  "purple": "qhV",
  "mint": "qhL",
  "mustard": "qhT",
  "peach": "qhz",
  "form-wrapper": "qha",
  "header-logo": "qhH",
  "links": "qhO",
  "no-account-label": "qhm",
  "spinner-container": "qhp",
  "main-image-container": "qhx",
  "main-image": "qhw",
  "signin-illustration": "qhN"
};

export default {
  "page": "qwa",
  "container": "qwH",
  "form-container": "qwO",
  "preview-container": "qwm",
  "pdf-preview-container": "qwp",
  "content": "qwx",
  "header": "qww",
  "close-button": "qwN btn btn--icon-only btn--tertiary btn--large"
};

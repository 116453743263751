export default {
  "mandates": "qpt",
  "mandates-empty": "qpM",
  "header": "qpP",
  "header-empty": "qpl",
  "header-content": "qpX",
  "search": "qpC",
  "search-bar": "qpk",
  "body": "qpJ",
  "isEmpty": "qps",
  "left-section": "qpF",
  "mandates-list": "qpg",
  "mandate-suspended": "qpD",
  "list-title": "qpV caption-bold",
  "list-empty": "qpL",
  "empty-illustration": "qpT",
  "empty-title": "qpz title-3",
  "empty-description": "qpa body-2",
  "body-details": "qpH",
  "l-app-content__page-header": "qpO",
  "pagination-footer": "qpm"
};

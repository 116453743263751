export default {
  "page-container": "qmB",
  "form": "qmE",
  "close-button": "qmd btn btn--icon-only btn--tertiary btn--large",
  "preview": "qmI",
  "preview-padding": "qmt",
  "title": "qmM",
  "header": "qmP",
  "without-tabs": "qml",
  "form-section": "qmX",
  "form-footer": "qmC",
  "disclaimer-text": "qmk"
};

export default {
  "vat-number": "qAw body-2",
  "banner": "qAN",
  "container": "qAv",
  "organization-profile": "qAo",
  "input": "qAn",
  "medium-input": "qAi medium",
  "small-input": "qAA small",
  "actions": "qAY",
  "company-info": "qAh",
  "company-info-section": "qAf",
  "company-address-section": "qAK",
  "company-country": "qAG",
  "company-billing-email": "qAr",
  "company-address": "qAb"
};

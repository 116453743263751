export default {
  "page-header": "qKw",
  "header-breadcrumb": "qKN",
  "tabs-container": "qKv",
  "bottom-border": "qKo",
  "tabs-separator": "qKn",
  "previous-request": "qKi",
  "body-link": "qKA",
  "border-top": "qKY"
};

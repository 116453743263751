export default {
  "multi-transfer": "qKB",
  "container": "qKE",
  "title": "qKd",
  "subtitle": "qKI",
  "form-wrapper": "qKt",
  "form": "qKM",
  "form-title": "qKP",
  "files-buttons": "qKl",
  "files-buttons-item": "qKX",
  "selected": "qKC",
  "ebics-button": "qKk",
  "divider": "qKJ"
};

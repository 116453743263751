import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { PdfPreview } from '@repo/domain-kit/invoices';
import { dropTask } from 'ember-concurrency';

export default class ReceivableInvoicesCreditNotesNewController extends Controller {
  @service router;
  @service segment;
  @service organizationManager;
  @service intl;
  @service modals;

  pdfPreviewComponent = PdfPreview;

  queryParams = ['invoiceId'];

  @tracked invoiceId = null;

  get logo() {
    let { organization } = this.organizationManager;
    return organization.get('isDefaultAvatar') === false ? organization.picture : null;
  }

  get documentVariant() {
    return this.organizationManager.organization.legalCountry;
  }

  // Ember - React compatibility getter, forces Ember to create a new ref for the prop on mutation updates
  get document() {
    // @ts-expect-error
    let items = this.model.creditNote.items?.map(item => ({
      id: item.id,
      title: item.title,
      description: item.description,
      quantity: item.quantity,
      unit: item.unit,
      unitPrice: item.unitPrice,
      vatRate: item.vatRate,
      totalExcludingVat: item.totalExcludingVat,
      discount: item.discount,
      totalDiscount: item.totalDiscount,
      links: item.links,
      subTotal: item.subTotal,
      totalAmount: item.totalAmount,
      totalVat: item.totalVat,
      vatExemptionCode: item.vatExemptionCode,
    }));

    return {
      number: this.model.creditNote.number,
      status: this.model.creditNote.status,
      currency: this.model.creditNote.currency,
      // @ts-expect-error
      isDeposit: this.model.creditNote.isDeposit,
      // @ts-expect-error
      reason: this.model.creditNote.reason,
      // @ts-expect-error
      bic: this.model.creditNote.bic,
      // @ts-expect-error
      iban: this.model.creditNote.iban,
      reference: this.model.creditNote.reference,
      beneficiaryName: this.model.creditNote.beneficiaryName,
      contactEmail: this.model.creditNote.contactEmail,
      issueDate: this.model.creditNote.issueDate,
      // @ts-expect-error
      performanceStartDate: this.model.creditNote.performanceStartDate,
      // @ts-expect-error
      performanceEndDate: this.model.creditNote.performanceEndDate,
      // @ts-expect-error
      expiryDate: this.model.creditNote.expiryDate,
      // @ts-expect-error
      dueDate: this.model.creditNote.dueDate,
      // @ts-expect-error
      depositInvoiceNumber: this.model.creditNote.depositInvoiceNumber,
      // @ts-expect-error
      depositPaymentDate: this.model.creditNote.depositPaymentDate,
      // @ts-expect-error
      purchaseOrder: this.model.creditNote.purchaseOrder,
      items,
      discount: this.model.creditNote.discount,
      totalExcludingVat: this.model.creditNote.totalExcludingVat,
      totalDiscount: this.model.creditNote.totalDiscount,
      discountedTotalExcludingVat: this.model.creditNote.discountedTotalExcludingVat,
      welfareFundAmount: this.model.creditNote.welfareFundAmount,
      displayEachVatSubtotals: this.model.creditNote.displayEachVatSubtotals,
      vatSubtotals: this.model.creditNote.vatSubtotals,
      totalVat: this.model.creditNote.totalVat,
      withholdingTax: this.model.creditNote.withholdingTax,
      withholdingTaxAmount: this.model.creditNote.withholdingTaxAmount,
      stampDutyAmount: this.model.creditNote.stampDutyAmount,
      totalAmount: this.model.creditNote.totalAmount,
      // @ts-expect-error
      depositAmount: this.model.creditNote.depositAmount,
      totalAmountDue: this.model.creditNote.totalAmountDue,
      header: this.model.creditNote.header,
      footer: this.model.creditNote.footer,
      termsAndConditions: this.model.creditNote.termsAndConditions,
      // @ts-expect-error
      pageCount: this.model.creditNote.pageCount,
      locale:
        this.model.creditNote.locale ?? this.model.receivableInvoice?.get?.('customer.locale'),
    };
  }

  get client() {
    let customer = this.model.receivableInvoice.get('customer');

    return customer
      ? {
          name: customer.get('name'),
          email: customer.get('email'),
          billingAddress: customer.get('billingAddress'),
          deliveryAddress: customer.get('deliveryAddress'),
          tinNumber: customer.get('tinNumber'),
          vatNumber: customer.get('vatNumber'),
          locale: customer.get('locale'),
        }
      : undefined;
  }

  get merchant() {
    let address = this.organizationManager.organization.address;
    let organizationStreetAddress = address.firstLine
      ? address.firstLine
          .concat(address.secondLine ? ` ${address.secondLine}` : '')
          .concat(address.thirdLine ? ` ${address.thirdLine}` : '')
      : '';

    return {
      legalName: this.organizationManager.organization.legalName,
      legalNumber: this.organizationManager.organization.legalNumber,
      legalCountry: this.organizationManager.organization.legalCountry,
      address: {
        streetAddress: organizationStreetAddress,
        zipCode: address.zipcode,
        city: address.city,
        countryCode: address.country,
      },
      taxNumber: this.organizationManager.organization.taxNumber,
      vatNumber: this.organizationManager.organization.vatNumber,
      contactEmail: this.organizationManager.organization.contactEmail,
      districtCourt: this.organizationManager.organization.districtCourt,
      commercialRegisterNumber: this.organizationManager.organization.commercialRegisterNumber,
      companyLeadership: this.organizationManager.organization.companyLeadership,
      shortLegalForm: this.organizationManager.organization.shortLegalForm,
      locale: this.organizationManager.organization.locale,
    };
  }

  get relatedInvoice() {
    let depositInvoice =
      this.model.receivableInvoice.get?.('depositInvoice') ??
      this.model.receivableInvoice.depositInvoice;

    return this.model.receivableInvoice
      ? {
          number: this.model.receivableInvoice.number,
          issueDate: this.model.receivableInvoice.issueDate,
          purchaseOrder: this.model.receivableInvoice.purchaseOrder,
          depositInvoice: depositInvoice
            ? {
                number: depositInvoice.number,
                issueDate: depositInvoice.issueDate,
                paidAt: depositInvoice.paidAt,
              }
            : undefined,
        }
      : undefined;
  }

  onCloseTask = dropTask(async () => {
    await this.modals.open('receivable-invoices/exit-credit-note-form-modal', {
      title: this.intl.t('receivable-invoices.credit-note-creation.exit-modal.title'),
      description: this.intl.t('receivable-invoices.credit-note-creation.exit-modal.description'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.leave'),
      confirmTask: this.onConfirmCloseTask,
    });
  });

  onConfirmCloseTask = dropTask(async close => {
    this.segment.track('credit-note_creation_canceled');
    this.router.transitionTo('receivable-invoices.index');
    await close();
  });
}

export default {
  "accounts": "qDL",
  "header-subtitle": "qDT title-4",
  "subtitle-container": "qDz",
  "subtitle": "qDa title-3 mb-16",
  "amount": "qDH body-2",
  "recommendations-section": "qDO",
  "dismissing": "qDm",
  "recommendations-divider": "qDp",
  "list--cashbeeSavings": "qDx",
  "list": "qDw",
  "icon": "qDN",
  "badge": "qDv",
  "discover-tile": "qDo"
};

export default {
  "container": "qix",
  "wrapper": "qiw",
  "grey-header-block": "qiN",
  "connect-app-header": "qiv",
  "connect-app-header-link": "qio",
  "connect-disclaimer-wrapper": "qin",
  "connect-app-header-content": "qii",
  "connect-app-header-cta": "qiA",
  "connect-app-content": "qiY",
  "connect-app-description": "qih",
  "connect-app-body": "qif"
};

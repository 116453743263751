export default {
  "main-container": "qLF",
  "main": "qLg",
  "main-wrapper": "qLD",
  "close-button": "qLV",
  "active-block": "qLL",
  "title": "qLT",
  "list-options": "qLz",
  "card": "qLa",
  "card-container": "qLH",
  "card-image": "qLO",
  "card-tiles": "qLm"
};

export default {
  "page-wrapper": "qhv",
  "content-wrapper": "qho",
  "content": "qhn",
  "period-field": "qhi",
  "format-options": "qhA",
  "format-option": "qhY",
  "format-radio": "qhh",
  "format-description": "qhf body-2"
};

export default {
  "page-wrapper": "qLv",
  "back-button": "qLo",
  "abort-button": "qLn",
  "container": "qLi",
  "subtitle": "qLA",
  "button": "qLY",
  "panel-list": "qLh",
  "panel-list-item": "qLf",
  "card-illustration": "qLK"
};

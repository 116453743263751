export default {
  "container": "qOJ",
  "caption": "qOs caption",
  "compact-header": "qOF",
  "visible": "qOg",
  "page-container": "qOD",
  "main": "qOV",
  "tabs": "qOL",
  "tabs-nav": "qOT",
  "tabs-panels": "qOz",
  "tabs-panel": "qOa body-2",
  "sidebar": "qOH",
  "cms-content": "qOO"
};

import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { receivableInvoiceV5Namespace } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ReceivableInvoicesShareRoute extends Route {
  @service menu;
  @service store;
  @service organizationManager;
  @service router;
  @service sentry;
  @service toastFlashMessages;
  @service intl;
  @service networkManager;
  @service mollie;

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  beforeModel() {
    if (variation('feature--boolean-ar-advanced-customization')) {
      this.store.adapterFor('receivable-invoice').namespace = receivableInvoiceV5Namespace;
      this.store.adapterFor('receivable-invoices-settings').namespace =
        receivableInvoiceV5Namespace;
    }
  }

  async model({ id }, transition) {
    this.origin = transition.from;

    let { organization } = this.organizationManager;

    this.fetchOrganizationAvatarTask.perform(organization).catch(ignoreCancelation);

    let organizationId = organization.id;

    let invoice = await this.store.findRecord('receivable-invoice', id).catch(error => {
      return this.handleError({
        error,
        redirect: 'receivable-invoices.index',
      });
    });

    let invoiceStats = await this.store.modelFor('receivable-invoice').getStats(this.store);

    await invoice.belongsTo('quote').load();

    let settings = await this.store
      .findRecord('receivable-invoices-settings', organizationId)
      .catch(error => {
        return this.handleError({
          error,
          redirect: 'receivable-invoices.index',
        });
      });

    await this.fetchAttachments.perform(invoice, settings).catch(ignoreCancelation);

    await this.fetchEmailTemplate.perform(id).catch(ignoreCancelation);

    await this.mollie.fetchConnectionTask.perform();

    return {
      invoice,
      invoiceStats,
      settings,
      origin: this.origin,
      emailTemplate: this.fetchEmailTemplate.lastSuccessful?.value.data.attributes,
      attachments: this.fetchAttachments.lastSuccessful?.value,
    };
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.setDefaultParams();
  }

  fetchEmailTemplate = dropTask(async id => {
    try {
      return await this.store.adapterFor('receivable-invoice').fetchEmailTemplate(id);
    } catch (error) {
      this.handleError({ error, redirect: 'receivable-invoices.index' });
    }
  });

  fetchOrganizationAvatarTask = dropTask(async organization => {
    try {
      await organization.getAvatar();
    } catch (error) {
      this.handleError({
        error,
        ignoreAdapterError: true,
      });
    }
  });

  fetchAttachments = dropTask(async (invoice, settings) => {
    if (!variation('feature--boolean-ar-advanced-customization')) {
      return [];
    }

    let attachments = [];
    try {
      let attachmentFileId = invoice.belongsTo('receivableInvoicingUpload').id();

      if (attachmentFileId) {
        let attachmentFile = await this.store.findRecord(
          'receivable-invoices-upload',
          attachmentFileId
        );
        attachments.push(attachmentFile);
      }

      let termsAndConditionsFileId = settings.belongsTo('termsConditionsUpload').id();

      if (termsAndConditionsFileId) {
        let termsAndConditionsFile = await this.store.findRecord(
          'receivable-invoices-upload',
          termsAndConditionsFileId
        );
        attachments.push(termsAndConditionsFile);
      }

      return attachments;
    } catch (error) {
      this.handleError(error);
    }
  });

  handleError({ error, ignoreAdapterError, redirect }) {
    if (ignoreAdapterError && error.isAdapterError) return;

    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry) {
      this.sentry.captureException(error);
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }

    if (redirect) {
      return this.router.transitionTo(redirect);
    }
  }
}

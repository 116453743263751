export default {
  "header": "qgo",
  "btn-preset": "qgn btn btn-filter mr-8 mb-8",
  "invoices-wrapper": "qgi",
  "statements-table": "qgA",
  "no-result": "qgY",
  "lottie-illustration": "qgh",
  "wrapper": "qgf",
  "access-denied": "qgK",
  "access-denied-illustration": "qgG",
  "application-card": "qgr",
  "presets-wrapper": "qgb",
  "error-wrapper": "qgy",
  "error-container": "qgU",
  "error-illustration": "qgj mb-32",
  "invoicing-info": "qgW"
};

export default {
  "container": "qxa",
  "content": "qxH",
  "logos": "qxO",
  "logo": "qxm",
  "animation": "qxp",
  "title": "qxx title-2 mb-32",
  "avatar": "qxw",
  "dropdown": "qxN",
  "disabled": "qxv",
  "organization": "qxo",
  "actions": "qxn"
};

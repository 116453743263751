export default {
  "container": "qib",
  "content": "qiy",
  "setup-box": "qiU",
  "setup-wizard": "qij",
  "setup-success": "qiW",
  "setup-success-lottie": "qiu",
  "setup-close-btn": "qAS",
  "tray-connection": "qAc",
  "close-button": "qAq"
};

export default {
  "container": "qNH",
  "form-container": "qNO",
  "preview-container": "qNm",
  "credit-note-preview-container": "qNp",
  "credit-note-preview": "qNx",
  "content": "qNw",
  "title": "qNN",
  "tabs-container": "qNv",
  "close-button": "qNo btn btn--icon-only btn--tertiary btn--large",
  "header": "qNn",
  "without-tabs": "qNi"
};

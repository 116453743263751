export default {
  "splash-landing": "qLZ",
  "container": "qLR",
  "options": "qLQ",
  "physical": "qLe",
  "card-label": "qLB",
  "physical-cta-container": "qLE",
  "physical-cta": "qLd",
  "coming-soon": "qLI"
};

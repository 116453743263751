export default {
  "container": "qod",
  "form-container": "qoI",
  "title": "qot",
  "title-border": "qoM",
  "tabs-container": "qoP",
  "preview-container": "qol",
  "email-preview-container": "qoX",
  "close-button": "qoC btn btn--icon-only btn--tertiary btn--large"
};

export default {
  "details": "qmb",
  "header": "qmy",
  "avatar": "qmU mr-16",
  "header-beneficiary": "qmj",
  "header-beneficiary-activity": "qmW",
  "header-beneficiary-details": "qmu",
  "header-beneficiary-details-recurring": "qpS",
  "infos": "qpc",
  "infos-title": "qpq",
  "infos-empty": "qpZ",
  "infos-list": "qpR",
  "infos-list-item": "qpQ",
  "infos-list-item-left": "qpe",
  "infos-list-item-right": "qpB",
  "infos-list-item-attachment": "qpE",
  "infos-transactions": "qpd",
  "actions": "qpI"
};

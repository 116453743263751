export default {
  "bank-accounts-show": "qDI",
  "header": "qDt l-app-content__page-header",
  "share-and-certificate": "qDM",
  "share": "qDP",
  "certificate": "qDl",
  "section-divider": "qDX",
  "content-wrapper": "qDC",
  "content": "qDk"
};
